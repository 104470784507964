import React, { useEffect, useState } from "react"
import P from "../../core/P"

export const Hiring = () => {
  const [hiring, setHiering] = useState([])

  useEffect(() => {
    async function asyncfn() {
      const url = "https://placement.webmobtech.com/api/getDepartmentList"

      let response = await fetch(url)
      const data = await response.json()
      setHiering(data)
    }
    asyncfn()
  }, [])
  // console.log("hiring", hiring)
  return (
    <div className="flex justify-center bg-blue-200">
      <div className="px-6 md:px-12 lg:px-28 py-12 w-screen ">
        <div
          className={"grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6"}
          data-aos="fade-up"
        >
          {hiring?.data?.departmentList?.map(e => (
            <div
              className={
                "bg-white rounded-md hover:shadow-xl hover:-translate-y-1 lg:h-44 md:h-44 h-40"
              }
              key={Math.random()}
            >
              <a
                href="https://placement.webmobtech.com/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="lg:my-5 lg:mx-6 md:m-6 m-6">
                  <div className="font-serif font-semibold text-xl tracking-normal leading-7 text-blue-500 ">
                    {e.name}
                  </div>
                  <hr className="my-4 border-green-500 bg-green-500 border-2 w-24" />
                  <P
                    title={`${e.opening} ${e.opening > 1 ? "Openings" : "Opening"
                      }`}
                    noPad
                    className="text-lg font-serif font-medium text-shark-500 mt-4"
                  />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Hiring
